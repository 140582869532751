/* eslint-disable react/jsx-no-target-blank */
import React from 'react'
import {useIntl} from 'react-intl'
import {SidebarMenuItemWithSub} from './SidebarMenuItemWithSub'
import {SidebarMenuItem} from './SidebarMenuItem'
import {isAuthorizedUser, userHasMainScope} from '../../../../../app/routing/utils/permissions'
import {getAuth, useAuth} from '../../../../../app/modules/auth'

const SidebarMenuMain = () => {
  const intl = useIntl()

  const { currentUser } = useAuth();

  return (
    <>
      <SidebarMenuItem
        to='/dashboard'
        icon='element-11'
        title={intl.formatMessage({id: 'MENU.DASHBOARD'})}
        fontIcon='bi-app-indicator'
      />

      {userHasMainScope(currentUser, ['sales', 'budgets', 'billings']) && (
        <SidebarMenuItemWithSub
          to='/sales'
          title='Vendas'
          fontIcon='bi-archive'
          icon='element-plus'
        >
          {isAuthorizedUser(currentUser, 'sales.view') && (
            <SidebarMenuItem to='/sales/list' title='Listar' hasBullet={true} />
          )}
          {(isAuthorizedUser(currentUser, 'sales.create') || isAuthorizedUser(currentUser, 'budgets.create')) && (
            <SidebarMenuItem to='/sales/create' title='Realizar venda' hasBullet={true} />
          )}
          {isAuthorizedUser(currentUser, 'billings.view') && (
            <SidebarMenuItem to='/sales/billings' title='Cobranças' hasBullet={true} />
          )}
          {isAuthorizedUser(currentUser, 'budgets.view') && (
            <SidebarMenuItem to='/sales/budgets' title='Orçamentos' hasBullet={true} />
          )}
        </SidebarMenuItemWithSub>
      )}

      {userHasMainScope(currentUser, ['franchises', 'machines']) && (
        <SidebarMenuItemWithSub
          to='/franchises'
          title='Franquias'
          fontIcon='bi-archive'
          icon='element-plus'
        >
          {isAuthorizedUser(currentUser, 'franchises.view') && (
            <SidebarMenuItem to='/franchises/list' title='Listar' hasBullet={true} />
          )}
          {isAuthorizedUser(currentUser, 'franchises.create') && (
            <SidebarMenuItem to='/franchises/create' title='Cadastrar' hasBullet={true} />
          )}
          {isAuthorizedUser(currentUser, 'machines.view') && (
            <SidebarMenuItem to='/franchises/machines' title='Listar Máquinas' hasBullet={true} />
          )}
        </SidebarMenuItemWithSub>
      )}

      <SidebarMenuItem to='/schedules' title='Agenda' fontIcon='bi-archive' icon='element-plus' />

      {userHasMainScope(currentUser, ['rooms']) && (
        <SidebarMenuItemWithSub to='/rooms' title='Salas' fontIcon='bi-archive' icon='element-plus'>
          {isAuthorizedUser(currentUser, 'rooms.view') && (
            <SidebarMenuItem to='/rooms/list' title='Listar' hasBullet={true} />
          )}
          {isAuthorizedUser(currentUser, 'rooms.create') && (
            <SidebarMenuItem to='/rooms/create' title='Cadastrar' hasBullet={true} />
          )}
        </SidebarMenuItemWithSub>
      )}

      {userHasMainScope(currentUser, ['persons', 'medicalConditions']) && (
        <SidebarMenuItemWithSub
          to='/people'
          title='Clientes'
          fontIcon='bi-archive'
          icon='element-plus'
        >
          {isAuthorizedUser(currentUser, 'persons.view') && (
            <SidebarMenuItem to='/people/list' title='Listar' hasBullet={true} />
          )}
          {isAuthorizedUser(currentUser, 'medicalConditions.view') && (
            <SidebarMenuItem
              to='/people/medicalConditions'
              title='Condição Médica'
              hasBullet={true}
            />
          )}
          {isAuthorizedUser(currentUser, 'persons.create') && (
            <SidebarMenuItem to='/people/create' title='Criar Cliente' hasBullet={true} />
          )}
        </SidebarMenuItemWithSub>
      )}

      {userHasMainScope(currentUser, ['users', 'groups']) && (
        <SidebarMenuItemWithSub
          to='/users'
          title='Usuários'
          fontIcon='bi-archive'
          icon='element-plus'
        >
          {isAuthorizedUser(currentUser, 'groups.view') && (
            <SidebarMenuItem to='/users/groups/list' title='Listar Grupos' hasBullet={true} />
          )}
          {isAuthorizedUser(currentUser, 'users.view') && (
            <SidebarMenuItem to='/users/list' title='Listar Usuários' hasBullet={true} />
          )}
        </SidebarMenuItemWithSub>
      )}

      {userHasMainScope(currentUser, ['categories', 'products']) && (
        <SidebarMenuItemWithSub
          to='/products'
          title='Produtos'
          fontIcon='bi-archive'
          icon='element-plus'
        >
          {isAuthorizedUser(currentUser, 'products.view') && (
            <SidebarMenuItem to='/products/list' title='Listar Produtos' hasBullet={true} />
          )}
          {isAuthorizedUser(currentUser, 'categories.view') && (
            <SidebarMenuItem
              to='/products/categories/list'
              title='Listar Categoria'
              hasBullet={true}
            />
          )}
        </SidebarMenuItemWithSub>
      )}

      {userHasMainScope(currentUser, ['paymentConfig']) && (
        <SidebarMenuItemWithSub
          to='/paymentOptions'
          title='Forma de Pgto'
          fontIcon='bi-archive'
          icon='element-plus'
        >
          {isAuthorizedUser(currentUser, 'paymentConfig.view') && (
            <SidebarMenuItem to='/paymentOptions' title='Listar Forma Pgto' hasBullet={true} />
          )}
        </SidebarMenuItemWithSub>
      )}
    </>
  )
}

export {SidebarMenuMain}
