import { useEffect, useState } from 'react'
import { getScheduleStatus } from '../../../coreGlobal/_resquest'
import { ISelectT } from '../../../coreGlobal/models/default'
import Select from 'react-select'
import moment from 'moment'
import 'moment/locale/pt-br'
import 'moment/locale/pt-br';
import useAuthorization from "../../../hooks/useAuthorization";

interface FormikMedicalRecord {
  formik: any
  data: any
}

const CANCELLED_LABELS = ['Cancelado Cliente', 'Cancelado Clínica', 'Agendado']
const TODAY_LABELS = ['Agendado', 'Confirmado', 'Compareceu', 'Cancelado Cliente', 'Cancelado Clínica', 'Faltou']
const TOMORROW_LABELS = ['Confirmado', 'Agendado', 'Cancelado Cliente', 'Cancelado Clínica']


export const StatusModalMedicalRecords = ({ data, formik }: FormikMedicalRecord) => {
  const initialSelectSingleValue = { value: '', label: '' }
  const [allStatus, setAllStatus] = useState<ISelectT[] | undefined>([])
  const [selectStatus, setSelectStatus] = useState<ISelectT>(initialSelectSingleValue)

  const statusNow = data?.status_id

  const setStatusValue = (dataStatus) => {
    setAllStatus(dataStatus)
    formik.setFieldValue('idStatus', data.id)
    formik.setFieldValue('observation', '')
  }

  useEffect(() => {
    async function init() {
      const responseStatus = await getScheduleStatus()
      const dateNow = moment().format('YYYY/MM/DD')
      const dateSchedule = moment(data?.dateInit).format('YYYY/MM/DD')

      // Verificação de datas
      const isBeforeSchedule = moment(dateNow).isBefore(moment(dateSchedule))
      const isTomorrowSchedule =
        moment(dateSchedule).subtract(1, 'day').format('YYYY/MM/DD') ===
        moment(dateNow).format('YYYY/MM/DD')
      const isTodaySchedule =
        moment(dateNow).format('YYYY/MM/DD') === moment(dateSchedule).format('YYYY/MM/DD')

      const filterStatus = (labels) => {
        return responseStatus.filter(
          (item) => labels.includes(item.label) && item.value !== statusNow
        )
      }

      if (isBeforeSchedule && !isTomorrowSchedule) {
        const dataQuery = filterStatus(CANCELLED_LABELS)
        setStatusValue(dataQuery)
        return
      }

      if (isTodaySchedule) {
        const dataQuery = filterStatus(TODAY_LABELS)
        setStatusValue(dataQuery)
        return
      }

      if (isTomorrowSchedule) {
        const dataQuery = filterStatus(TOMORROW_LABELS)
        setStatusValue(dataQuery)
        return
      }
    }
    init()
  }, [])

  const isAuthorizedToChangeStatus = useAuthorization("schedules.update");

  return (
    <>
      <form
        className='mx-auto mw-750px w-300 pb-10'
        id='updateStatusSchedule'
        onSubmit={formik.handleSubmit}
      >
        <div className='row mt-5'>
          <div className='col-12 mt-4'>
            <div className='col-6'>
              <b>Status do agendamento</b>
              <label htmlFor='' className='required'>
              </label>
            </div>
            <div className='col-12'>
              <Select
                isDisabled={statusNow === 8 || statusNow === 4 || statusNow === 5 || statusNow === 6 || statusNow === 7 || !isAuthorizedToChangeStatus}
                className='react-select-styled react-select-solid'
                classNamePrefix='react-select'
                value={selectStatus}
                onChange={(option) => {
                  const values = option?.value
                  formik.setFieldValue(`status`, values)
                  if (option) {
                    const filterData = {
                      label: option.label,
                      value: option.value,
                    }
                    setSelectStatus(filterData)
                  }
                }}
                options={allStatus}
              />
            </div>
            {formik.touched.status && formik.errors.status ? (
              <small className='text-danger'>{formik.errors.status}</small>
            ) : null}
          </div>

          <div className='col-12 mt-4'>
            <div className='col-6'>
              <label htmlFor='' className={formik.values.status === 5 || formik.values.status === 6 ? 'required' : ''}><b>Observação</b></label>
            </div>
            <div className='col-12'>
              <input
                type='text'
                className='form-control'
                disabled={statusNow === 8 || statusNow === 4 || statusNow === 5 || statusNow === 6 || statusNow === 7 || !isAuthorizedToChangeStatus}
                onChange={(e) => {
                  const value = e.target.value
                  formik.setFieldValue('observation', value)
                }}
              />
            </div>
            {formik.touched.observation && formik.errors.observation ? (
              <small className='text-danger'>{formik.errors.observation}</small>
            ) : null}
          </div>
        </div>

        <div className='col-12 mt-4'>
          <h3>Histórico</h3>
          <table>
            <thead>
              <tr>
                <th className='fw-bold' style={{ color: 'red' }}>
                  Status
                </th>
                <th className='fw-bold text-center' style={{ color: 'red' }}>
                  Data
                </th>
                <th className='fw-bold' style={{ color: 'red' }}>
                  Observação
                </th>
                <th className='fw-bold' style={{ color: 'red' }}>
                  Operador
                </th>
              </tr>
            </thead>
            <tbody>
              {data.history.map((item, index) => (
                <tr key={index}>
                  <td className='fw-bold'>{item.status.name}</td>
                  <td className='fw-bold'>
                    {moment(item.createdAt).format('DD/MM/YYYY HH:mm:ss')}
                  </td>
                  <td className='text-center fw-bold'>{item.observation}</td>
                  <td className='text-center fw-bold'>{item.user.name}</td>
                </tr>
              ))}
            </tbody>
          </table>
        </div>
      </form>
    </>
  )
}
