import { formatDateTime } from "../../../utils/dayJs/format"

interface ISaleID {
  history: any
}

export const ListSalesStatus = ({history}: ISaleID) => {
  return (
    <>
      <div className='card-header cursor-pointer'>
        <div className='card-title m-0'>
          <h3 className='fw-bolder m-0'>Informações do Prontuário</h3>
        </div>
      </div>
      <div className='card-body py-3'>
        <div className='table-responsive'>
          <table className='table table-row-bordered table-row-gray-100 align-middle gs-0 gy-3'>
            <thead>
              <tr className='fw-bolder fs-6 text-gray-800'>
                <th>Status</th>
                <th>Observação</th>
                <th>Criado em</th>
                <th>Atualizado em</th>
              </tr>
            </thead>
            <tbody>
              {history?.map((stts, index) => {
                let badgeClass = ''
                switch (stts.status.name) {
                  case 'Inadimplente':
                    badgeClass = 'warning'
                    break
                  case 'Assinado':
                    badgeClass = 'success'
                    break
                  default:
                    badgeClass = 'primary'
                    break
                }
                return (
                  <tr key={`historyTr${index}`}>
                    <td>
                      <span className={`badge badge-${badgeClass}`}>{stts.status.name}</span>
                    </td>
                    <td className='fw-bold fs-6'>{stts.observation}</td>
                    <td className='fw-bold fs-6'>{formatDateTime(stts.createdAt)}</td>
                    <td className='fw-bold fs-6'>{formatDateTime(stts.updatedAt)}</td>
                  </tr>
                )
              })}
            </tbody>
          </table>
        </div>
      </div>
    </>
  )
}
