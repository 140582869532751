import axios, { AxiosResponse } from 'axios'
import { Response } from '../_metronic/helpers'
import { TViaCepResponse } from "./models/Address/viaCep"
import { TStateResponse } from "./models/Address/address"
import { ISelect } from './models/default'
import { Option } from 'react-multi-select-component'
import { TMedicalConditionPersonRequest } from './models/medicalCondition'

const API_URL = process.env.REACT_APP_API_URL + '/api'
const STATE_URL = `${API_URL}/state`
const COUNTRIES_URL = `${API_URL}/nationalities`
const BANK_URL = `${API_URL}/banks`
const MEDICAL_CONDITION_URL = `${API_URL}`
const MACHINE_URL = `${API_URL}/machines`
const PRODUCTS_URL = `${API_URL}/products`
const USERS_URL = `${API_URL}/users`
const PEOPLE_URL = `${API_URL}/people`
const SCHEDULE_URL = `${API_URL}/schedules`
const ROOM_URL = `${API_URL}/rooms`
const VALIDATION_URL = `${API_URL}`
const FILE_URL = `${API_URL}/files`
const FIND_CEP_URL = `${API_URL}/findCep`

const getCepByViaCep = (cep: string): Promise<TViaCepResponse> => {
  return axios
    .get(`${FIND_CEP_URL}/${cep}`)
    .then((response: any) => response.data)
}

const getStatesAndCities = (): Promise<TStateResponse[] | undefined> => {
  return axios
    .get(`${STATE_URL}?include=cities&per_page=27`)
    .then((response: AxiosResponse<Response<TStateResponse[]>>) => response.data)
    .then((response: Response<TStateResponse[]>) => response.data)
}

const getCountries = (): Promise<ISelect[] | undefined> => {
  return axios
    .get(`${COUNTRIES_URL}/all`)
    .then((response: AxiosResponse<Response<ISelect[]>>) => response.data)
    .then((response: Response<ISelect[]>) => response.data)
}

const getBanks = (): Promise<ISelect[] | undefined> => {
  return axios
    .get(`${BANK_URL}/all`)
    .then((response: AxiosResponse<Response<ISelect[]>>) => response.data)
    .then((response: Response<ISelect[]>) => response.data)
}

const getMedicalConditions = (): Promise<TMedicalConditionPersonRequest[] | undefined> => {
  return axios
    .get(`${MEDICAL_CONDITION_URL}/medical-conditions`)
    .then((response: AxiosResponse<Response<TMedicalConditionPersonRequest[]>>) => response.data)
    .then((response: Response<TMedicalConditionPersonRequest[]>) => response.data)
}

const getMachines = (): Promise<ISelect[] | undefined> => {
  return axios
    .get(`${MACHINE_URL}/all`)
    .then((response: any) => response.data)
    .then((response: any) => response.data)
}

const getProducts = (): Promise<Option[] | undefined> => {
  return axios.get(`${PRODUCTS_URL}/all`)
    .then((response: AxiosResponse<Response<Option[]>>) => response.data)
    .then((response: Response<Option[]>) => response.data)
}

const getUsers = (query?: string): Promise<ISelect[] | undefined> => {
  return axios.get(`${USERS_URL}/all` + (query ? `?${query}` : ''))
    .then((response: any) => response.data)
    .then((response: any) => response.data)
}

const listClients = (query) => {
  return axios
    .get(`${PEOPLE_URL}/find${query}`)
    .then((response: any) => response.data)
    .then((response: any) => response.data)
}

const getClient = (clientId) => {
  return axios
    .get(`${PEOPLE_URL}/${clientId}`)
    .then((response: any) => response.data)
    .then((response: any) => response.data)
}

const updatePerson = async (personId: string | number, data: FormData) => {
  return await axios.post(`${PEOPLE_URL}`, data)
    .then((response: any) => response.data)
    .then((response: any) => response.data)
}

const createPerson = async (data: FormData) => {
  return await axios.post(`${PEOPLE_URL}`, data)
    .then((response: any) => response.data)
    .then((response: any) => response.data)
}

const getPersonById = (personId: string | number) => {
  return axios
    .get(`${PEOPLE_URL}/${personId}?include=sales.items.product,sales.items.seller,budgets.items.product,clientHistory.status,lastClientHistory.status,medicalConditions,sales.history`)
    .then((response: any) => response.data)
    .then((response: any) => response.data)
}

const getSchedulesByContractId = (contractId: string | number) => {
  return axios
    .get(`${PEOPLE_URL}/sales/${contractId}/schedules`)
    .then((response: any) => response.data)
    .then((response: any) => response.data)
}

const getSchedulesByPersonId = (personId: string | number) => {
  return axios
    .get(`${PEOPLE_URL}/sales/${personId}/medical-records`)
    .then((response: any) => response.data)
    .then((response: any) => response.data)
}

const getSchedulesItemsByPersonId = (peopleId: string | number) => {
  return axios
    .get(`${PEOPLE_URL}/${peopleId}/schedule-items`)
    .then((response: any) => response.data)
    .then((response: any) => response.data)
}

const getPeopleMedicalRecordsById = (peopleId: number) => {
  return axios
    .get(`${PEOPLE_URL}/${peopleId}/medical-records`)
    .then((response: any) => response.data)
    .then((response: any) => response.data)
}

const getEvaluationAppointmentsById = (peopleId: string | number) => {
  return axios
    .get(`${PEOPLE_URL}/${peopleId}/evaluation-appointments`)
    .then((response: any) => response.data)
    .then((response: any) => response.data)
}

export const createScheduleStatus = (data: { status: number; observation: string }, id) => {
  return axios
    .put(`${SCHEDULE_URL}/${id}/status`, data)
    .then((response: any) => response.data)
    .then((response: any) => response.data)
}

export const getScheduleStatus = () => {
  return axios
    .get(`${SCHEDULE_URL}/status`,)
    .then((response: any) => response.data)
    .then((response: any) => response.data)
}

export const getRooms = () => {
  return axios
    .get(`${ROOM_URL}?include=intervals`,)
    .then((response: any) => response.data)
    .then((response: any) => response.data)
}

export const validatePerson = (document: string) => {
  return axios
    .get(`${PEOPLE_URL}/validatePerson?document=${document}`,)
    .then((response: any) => response.data)
    .then((response: any) => response.data)
}

export const changePassword = (password: string, password_confirmation: string) => {
  const arrayPassword = {
    password: password,
    password_confirmation: password_confirmation
  }

  return axios
    .put(`${USERS_URL}/change-password`, arrayPassword)
    .then((response: any) => response.data)
    .then((response: any) => response.data)
}

export const resetPassword = (password: string, password_confirmation: string, token: string, email: string) => {
  const arrayPassword = {
    token: token,
    email: email,
    password: password,
    password_confirmation: password_confirmation
  }

  return axios
    .post(`${API_URL}/reset-password`, arrayPassword)
    .then((response: any) => response.data)
    .then((response: any) => response.data)
}

export const getPaymentOptionFranchise = (franchiseID: string) => {
  return axios
    .get(`${API_URL}/payment-franchise-options/${franchiseID}`,)
    .then((response: any) => response.data)
    .then((response: any) => response.data)
}

const validatePersonPhone = (number: string) => {
  return axios
    .get(`${VALIDATION_URL}/validation/phone/${number}`,)
    .then((response: any) => response.data)
    .then((response: any) => response.data)
}

const downloadServerFile = (path: string) => {
  return axios({
    url: `${FILE_URL}/download?path=${path}`, //your url
    method: 'GET',
    responseType: 'blob', // important
  }).then((response) => {
    const FileEXT = response.data.type.split('/')[1];
    const href = URL.createObjectURL(response.data);
    const link = document.createElement('a');
    link.href = href;
    link.setAttribute('download', `file.${FileEXT}`); //or any other extension
    document.body.appendChild(link);
    link.click();
    // clean up "a" element & remove ObjectURL
    document.body.removeChild(link);
    URL.revokeObjectURL(href);
  });
}

export { getCepByViaCep, getStatesAndCities, getCountries, getBanks, getMedicalConditions, getMachines, getProducts, getUsers, listClients, updatePerson, createPerson, getPersonById, getSchedulesByContractId, getEvaluationAppointmentsById, getPeopleMedicalRecordsById, getSchedulesByPersonId, getSchedulesItemsByPersonId, validatePersonPhone, downloadServerFile }
