import clsx from 'clsx'
import { toAbsoluteUrl } from '../../../helpers'
import { HeaderUserMenu } from '../../../partials'
import { useAuth } from '../../../../app/modules/auth'
import { useEffect, useState } from 'react'
import { chooseUserFranchise } from '../../../../pages/chooseFranchise/core/_request'
import { Franchise } from '../../../../pages/franchise/list/core/_models'
import { getFranchises } from '../../../../pages/chooseFranchise/core/_request'
import Swal from 'sweetalert2'

const itemClass = 'ms-1 ms-md-4'
const btnClass =
  'btn btn-icon btn-custom btn-icon-muted btn-active-light btn-active-color-primary w-35px h-35px'
const userAvatarClass = 'symbol-35px'
const btnIconClass = 'fs-2'

const Navbar = () => {

  const [loadingPage, setLoadingPage] = useState(false)
  const { currentUser, currentFranchise } = useAuth()
  const [franchises, setFranchises] = useState([])

  useEffect(() => {
    if (currentUser?.firstLogin) { return }
    async function init() {
      setLoadingPage(true)
      const franchises = await getFranchises()
      setFranchises(franchises)
      setLoadingPage(false)
    }
    init();
  }, [])

  const chooseFranchise = async (value) => {
    await Swal.fire({
      title: 'Deseja trocar de Franquia?',
      icon: 'warning',
      showCancelButton: true,
      confirmButtonColor: '#3085d6',
      cancelButtonColor: '#d33',
      confirmButtonText: 'Confirmar',
      cancelButtonText: 'Cancelar'
    }).then(async (result) => {
      if (result.isConfirmed) {
        setLoadingPage(true)
        try {
          await chooseUserFranchise({ franchise: value })
          window.location.reload()
        } catch (error) {
          await Swal.fire({
            icon: 'warning',
            title: 'Oops...',
            text: 'Tente novamente mais tarde!',
            timer: 1500
          })
          window.location.reload()
        } finally {
          setLoadingPage(false)
        }
      }
    })
  }

  return (
    <>
      <div className='app-navbar flex-shrink-0'>
        {loadingPage ? (
          <div className="spinner-border text-dark mt-4" role="status" style={{ width: '3rem', height: '3rem' }}></div>
        ) :
          <select
            name="chooseFranchise"
            id="franchise_id"
            className='form-select form-select-sm '
            value={currentFranchise?.person?.juridicalPerson?.cnpjWithoutMask}
            onChange={(e) => {
              chooseFranchise(e.target.value)
            }}
          >
            {/* {franchises.map((franchise: Franchise, index) => {
            return(
              <option key={index} selected={userFranchise == franchise.id } value={String(franchise.person?.juridicalPerson?.cnpjWithoutMask)}> {franchise.name} </option>
              
            )
          })} */}

            {franchises.map((franchise: Franchise, index) => (
              <option key={index} value={String(franchise.person?.juridicalPerson?.cnpjWithoutMask)}>
                {franchise.name}
              </option>
            ))}
          </select>
        }
        <div className={clsx('app-navbar-item', itemClass)}>
          <div className='d-none d-md-flex flex-column'>
            <div className='fw-bolder d-flex align-items-center fs-5'>{currentUser?.name}</div>
            <div className='fw-bold text-muted text-hover-primary fs-7 me-2'>
              {currentUser?.email}
            </div>
          </div>
          <div
            className={clsx('cursor-pointer symbol', userAvatarClass)}
            data-kt-menu-trigger="{default: 'click'}"
            data-kt-menu-attach='parent'
            data-kt-menu-placement='bottom-end'
          >
            <img src={toAbsoluteUrl('/media/avatars/blank.png')} alt='' />
          </div>
          <HeaderUserMenu />
        </div>
      </div>
    </>
  )
}

export { Navbar }