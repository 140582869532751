import {useEffect, useState} from 'react'
import {usePeople} from '../../coreGlobal/providers/PersonProvider'
import {
  createMedicalRecordschemas,
  scheduleStatusSchema,
  tokenMedicalRecordschemas,
} from './core/schemaMedicalRecord'
import {useFormik} from 'formik'
import {
  MedicalRecordInitialValues,
  MedicalRecordRequest,
  MedicalRecordUpdateInitialValues,
  MedicalRecordUpdateRequest,
} from '../../coreGlobal/models/MedicalRecords/medicalRecord'
import Swal from 'sweetalert2'
import {
  createMedicalRecord,
  getScheduleById,
  updateHighlightObs,
  updateMedicalRecord,
} from './core/_request'
import {createScheduleStatus} from '../../coreGlobal/_resquest'
import moment from 'moment'
import {phoneMask} from '../chooseFranchise/utils/mask'
import {KTIcon, toAbsoluteUrl} from '../../_metronic/helpers'
import {CreateModalMedicalRecords} from './medicalRecord/medicalRecordCreate'
import {UpdateModalMedicalRecords} from './medicalRecord/medicalRecordUpdate'
import {StatusModalMedicalRecords} from './medicalRecord/medicalRecordChangeStatus'
import {PersonEditModal} from '../../components/Person/PersonEditModal'
import {TokenModalMedicalRecords} from './medicalRecord/medicalRecordToken'
import {useScheduleCart} from './core/ClientScheduleProvider'
import {HighlightObsModal} from './components/HighlightObsModal'
import {buildCreateMedicalRecordRequest} from './utils/buildCreateRequest'
import {isColorDark} from '../../utils/isColorDark'
import {useQuery, useQueryClient} from 'react-query'
import {parseToEvent} from './utils/parseToEvents'
import {LoadingSchedules} from './components/alerts/loadingSchedules'
import {PlaceholderHeader} from './components/medicalRecordsModal/placeholderHeader'
import {PlaceholderBody} from './components/medicalRecordsModal/placeholderBody'
import {getStatusClient} from './utils/getStatusClient'
import {MedicalConditionMedicalRecords} from './medicalRecord/medicalRecordMedicalCondition'
import {ModalMedicalRecordToken} from './components/TokenMedicalRecord/modalMedicalRecordToken'
import useAuthorization from '../../hooks/useAuthorization'

const currentSchema = createMedicalRecordschemas[0]
const currentTokenSchema = tokenMedicalRecordschemas[0]

export const MedicalRecords = ({id, close}) => {
  const queryClient = useQueryClient()

  const {showEditPersonModal, setShowEditPersonModal} = usePeople()

  const [hasChanges, setHasChanges] = useState(false) // Estado para rastrear alterações
  const [dataMedicalRecords, setdataMedicalRecords] = useState<any>()
  const [openModal, setOpenModal] = useState<boolean>(false)
  const [openModalStatus, setOpenModalStatus] = useState<boolean>(false)
  const [openModalUpdate, setOpenModalUpdate] = useState<boolean>(false)
  const [openModalToken, setOpenModalToken] = useState<boolean>(false)
  const [highlight, setHighlight] = useState<boolean>(false)
  const [showHighlightObsModal, setShowHighlightObsModal] = useState<boolean>(false)
  const [openMedicalContidition, setOpenMedicalContidition] = useState<boolean>(false)
  const [openModalTokenMedicalRecord, setOpenModalTokenMedicalRecord] = useState(false)

  const formik = useFormik({
    initialValues: MedicalRecordInitialValues,
    validationSchema: currentSchema,
    onSubmit: (values) => {
      handleSubmit(values)
    },
  })

  const formikStatus = useFormik({
    initialValues: {status: -1, observation: ''},
    isInitialValid: true,
    validationSchema: scheduleStatusSchema,
    onSubmit: (values) => {
      handleSubmitStatus(values)
    },
  })

  const formikUpdate = useFormik({
    initialValues: MedicalRecordUpdateInitialValues,
    validationSchema: currentSchema,
    onSubmit: (values) => {
      handleSubmitUpdate(values)
    },
  })

  const formikToken = useFormik({
    initialValues: [],
    validationSchema: currentTokenSchema,
    onSubmit: (values) => {
      handleSubmitToken(values)
    },
  })

  function handleCheckboxChange() {
    setHighlight(!highlight)
    if (highlight) {
      const req = {highlight: false}
      Swal.fire({
        title: 'Deseja remover o destacar agendamento para aplicação?',
        icon: 'question',
        showCancelButton: true,
        confirmButtonColor: '#3085d6',
        cancelButtonColor: '#d33',
        confirmButtonText: 'Confirmar',
        cancelButtonText: 'Cancelar',
      }).then(async (result) => {
        if (result.isConfirmed) {
          try {
            const data = await updateHighlightObs(req, schedule.id)
            setHasChanges(true)
            refetch()
            await Swal.fire({
              position: 'center',
              icon: 'success',
              title: 'Salvo com sucesso',
              showConfirmButton: false,
              timer: 1500,
            })
          } catch (error: any) {
            setHighlight(true)
            if (error.response.status === 422) {
              let errorText = ''
              for (let key in error.response.data.errors) {
                errorText += '<li>' + error.response.data.errors[key][0] + '</li>'
              }
              await Swal.fire({
                icon: 'error',
                title: 'Ops! Revise seu formulário e tente novamente!',
                html: errorText,
              })
            } else {
              await Swal.fire({
                icon: 'error',
                title: 'Oops...',
                text: error.response.data.message,
                timer: 2000,
              })
            }
          } finally {
          }
        } else {
          setHighlight(true)
        }
      })
    } else {
      setShowHighlightObsModal(true)
    }
  }

  const isAuthorizedToUpdateMR = useAuthorization('schedules.update')
  function openUpdateMR(data) {
    setOpenModalUpdate(true)
    setdataMedicalRecords(data)
  }

  function openCreateMR(data) {
    setOpenModalUpdate(false)
    setdataMedicalRecords(data)
    setOpenModal(true)
  }

  function openMedicalRecord(data) {
    setOpenModalStatus(false)
    data.medicalRecord ? openUpdateMR(data) : openCreateMR(data)
  }

  function closeModalMR() {
    setOpenModal(false)
    setOpenModalStatus(false)
    setOpenModalUpdate(false)
  }

  const isAuthorizedToViewConditionMedical = useAuthorization('medicalConditions.view')
  function openContiditionMedicalModal() {
    setOpenMedicalContidition(true)
    setOpenModal(false)
    setOpenModalStatus(false)
    setOpenModalUpdate(false)
  }

  function openToken() {
    setOpenModalToken(true)
  }

  const openMDStatus = () => {
    setOpenModalUpdate(false)
    setOpenModal(false)
    setOpenModalStatus(true)
  }

  const getNameStatusSchedule = (status) => {
    const statusName = {
      1: 'Agendado',
      2: 'Confirmado',
      3: 'Compareceu',
      4: 'Faltou',
      5: 'Cancelado Cliente',
      6: 'Cancelado Clínica',
      7: 'Autorizado',
      8: 'Finalizado',
    }
    return statusName[status] || 'Status não encontrado'
  }

  const handleSubmit = async (values: MedicalRecordRequest) => {
    try {
      await Swal.fire({
        title: 'Deseja salvar o prontuário ?',
        icon: 'warning',
        showCancelButton: true,
        confirmButtonColor: '#3085d6',
        cancelButtonColor: '#d33',
        confirmButtonText: 'Confirmar',
        cancelButtonText: 'Cancelar',
      }).then(async (result) => {
        if (result.isConfirmed) {
          try {
            const data = buildCreateMedicalRecordRequest(values)
            await createMedicalRecord(data)
            setHasChanges(true)
            await Swal.fire({
              position: 'center',
              icon: 'success',
              title: 'Salvo com sucesso',
              showConfirmButton: false,
              timer: 1500,
            })
            setOpenModal(false)
            refetch()
          } catch (error: any) {
            await Swal.fire({
              icon: 'warning',
              title: 'Oops...',
              text: error.response.data.message,
              timer: 4000,
            })
          } finally {
          }
        }
      })
    } catch (error) {
      console.error('Erro ao enviar os dados:', error)
    }
  }

  const handleSubmitStatus = async (values: {status: number; observation: string}) => {
    const idStatus = formikStatus.getFieldProps('idStatus')
    if (values.status === 3) {
      openToken()
    } else {
      try {
        await Swal.fire({
          title: 'Deseja salvar o status ?',
          icon: 'warning',
          showCancelButton: true,
          confirmButtonColor: '#3085d6',
          cancelButtonColor: '#d33',
          confirmButtonText: 'Confirmar',
          cancelButtonText: 'Cancelar',
        }).then(async (result) => {
          if (result.isConfirmed) {
            try {
              setOpenModalStatus(false)
              await createScheduleStatus(values, idStatus.value)
              setHasChanges(true)
              if (values.status === 6) {
                queryClient.invalidateQueries(['filteredData'])
                queryClient.removeQueries('fetchMedicalRecord')
                close()
                return
              }
              if (values.status != 6) {
                refetch()
              }

              await Swal.fire({
                position: 'center',
                icon: 'success',
                title: 'Salvo com sucesso',
                showConfirmButton: false,
                timer: 1500,
              })
            } catch (error: any) {
              if (error.response.status === 422) {
                let errorText = ''
                for (let key in error.response.data.errors) {
                  errorText += '<li>' + error.response.data.errors[key][0] + '</li>'
                }
                await Swal.fire({
                  icon: 'error',
                  title: 'Ops! Revise seu formulário e tente novamente!',
                  html: errorText,
                })
              } else {
                await Swal.fire({
                  icon: 'error',
                  title: 'Oops...',
                  text: error.response.data.message,
                  timer: 2000,
                })
              }
            } finally {
            }
          }
        })
      } catch (error) {
        console.error('Erro ao enviar os dados:', error)
      }
    }
  }

  const handleSubmitUpdate = async (values: MedicalRecordUpdateRequest) => {
    const id = formikUpdate.getFieldProps('id')
    try {
      await Swal.fire({
        title: 'Deseja atualizar o prontuário?',
        icon: 'warning',
        showCancelButton: true,
        confirmButtonColor: '#3085d6',
        cancelButtonColor: '#d33',
        confirmButtonText: 'Confirmar',
        cancelButtonText: 'Cancelar',
      }).then(async (result) => {
        if (result.isConfirmed) {
          try {
            await updateMedicalRecord(values, Number(id.value))
            refetch()
            setHasChanges(true)
            await Swal.fire({
              position: 'center',
              icon: 'success',
              title: 'Salvo com sucesso',
              showConfirmButton: false,
              timer: 1500,
            })
            setOpenModalUpdate(false)
          } catch (error: any) {
            if (error.response.status === 422) {
              let errorText = ''
              for (let key in error.response.data.errors) {
                errorText += '<li>' + error.response.data.errors[key][0] + '</li>'
              }
              await Swal.fire({
                icon: 'error',
                title: 'Ops! Revise seu formulário e tente novamente!',
                html: errorText,
              })
            } else {
              await Swal.fire({
                icon: 'error',
                title: 'Oops...',
                text: error.response.data.message,
                timer: 2000,
              })
            }
          } finally {
          }
        }
      })
    } catch (error) {
      console.error('Erro ao enviar os dados:', error)
    }
  }

  const handleSubmitToken = async (values) => {
    try {
      await Swal.fire({
        title: 'Deseja enviar o token ?',
        icon: 'warning',
        showCancelButton: true,
        confirmButtonColor: '#3085d6',
        cancelButtonColor: '#d33',
        confirmButtonText: 'Confirmar',
        cancelButtonText: 'Cancelar',
      }).then(async (result) => {
        if (result.isConfirmed) {
          try {
            const arrayEdit = {
              status: 7,
              observation: 'Autorizado',
            }
            const idStatus = schedule.id

            await createScheduleStatus(arrayEdit, Number(idStatus))
            setHasChanges(true)
            setOpenModalStatus(false)
            await Swal.fire({
              position: 'center',
              icon: 'success',
              title: 'Salvo com sucesso',
              showConfirmButton: false,
              timer: 1500,
            })
            setOpenModalToken(false)
            refetch()
          } catch (error: any) {
            if (error.response.status === 422) {
              let errorText = ''
              for (let key in error.response.data.errors) {
                errorText += '<li>' + error.response.data.errors[key][0] + '</li>'
              }
              await Swal.fire({
                icon: 'error',
                title: 'Ops! Revise seu formulário e tente novamente!',
                html: errorText,
              })
            } else {
              await Swal.fire({
                icon: 'error',
                title: 'Oops...',
                text: error.response.data.message,
                timer: 2000,
              })
            }
          }
        }
      })
    } catch (error) {
      console.error('Erro ao enviar os dados:', error)
    }
  }

  const {
    data: schedule,
    isLoading,
    isRefetching,
    refetch,
    isError,
  } = useQuery(['fetchMedicalRecord'], () => getScheduleById(id), {
    select: (data) => parseToEvent(data),
    refetchIntervalInBackground: false,
    refetchOnWindowFocus: false,
    retry: 0,
  })

  const handleClose = () => {
    queryClient.removeQueries('fetchMedicalRecord')
    if (hasChanges) {
      queryClient.invalidateQueries(['filteredData'])
    }
    close()
  }

  useEffect(() => {
    if (isLoading) return
    if (schedule.status_id === 3) {
      openToken()
    }

    setHighlight(schedule.highlight)
  }, [schedule])

  const onErrorApi = async () => {
    await Swal.fire({
      text: 'Não foi possivel obter o agendamento, tente novamente mais tarde',
      icon: 'error',
      allowOutsideClick: false,
      confirmButtonColor: '#3085d6',
      confirmButtonText: 'ok',
    }).then((result) => {
      if (result.isConfirmed) {
        close()
      }
    })
  }

  useEffect(() => {
    if (isError) {
      onErrorApi()
    }
  }, [isError])

  const clientStatus = getStatusClient(schedule?.client?.lastClientHistory?.status_id)

  const authorizedMedicalCondition = schedule?.client?.medicalConditions.filter((s) => {
    return s.clientMedicalCondition.authorized !== true
  })

  const isAuthorizedToViewPerson = useAuthorization('persons.view')
  const isAuthorizedToUpdatePerson = useAuthorization('persons.view')
  const isAuthorizedToSkipRequestToken = useAuthorization('schedules.approve_execution')

  return (
    <>
      {isLoading && <LoadingSchedules title={'Buscando agendamento'} />}
      {isRefetching && <LoadingSchedules title={'Atualizando agendamento'} />}
      <div className='modal fade show d-block'>
        <div className='modal-dialog modal-dialog-centered mw-900px'>
          <div className='modal-content' style={{margin: '30px'}}>
            {isLoading || isError ? (
              <PlaceholderHeader />
            ) : (
              <div className='modal-header'>
                <div className='col-4'>
                  <h2 className='fw-bolder'>Agendamento</h2>
                </div>
                <div className='col-6 text-start'>
                  <h4 className='fw-bolder'>
                    {moment(schedule.dateInit).format('DD/MM/YYYY HH:mm:ss')}
                  </h4>
                </div>
                <div
                  className='btn btn-icon btn-sm btn-active-icon-primary'
                  data-kt-users-modal-action='close'
                  onClick={() => handleClose()}
                  style={{cursor: 'pointer'}}
                >
                  <KTIcon iconName='cross' className='fs-1' />
                </div>
              </div>
            )}

            {isLoading || isError ? (
              <PlaceholderBody />
            ) : (
              <form
                className='mx-auto mw-750px w-300 pb-10'
                id='kt_create_account_form'
                onSubmit={formik.handleSubmit}
              >
                <div className='row m-8'>
                  <div className='col-12'>
                    <div className='row'>
                      <div className='col-8 text-start mb-5'>
                        <h6>Status do agendamento</h6>
                        <button
                          style={{
                            borderRadius: '30px',
                            backgroundColor: schedule.colorScheduleStatus,
                            width: '150px',
                            padding: '6px',
                            color: isColorDark(schedule.colorScheduleStatus) ? 'white' : 'black',
                          }}
                          type='button'
                          onClick={() => openMDStatus()}
                        >
                          {getNameStatusSchedule(schedule.status_id)}
                        </button>
                      </div>
                      <div className='col-3 text-start'>
                        <div className='form-check form-switch form-check-custom form-check-solid'>
                          <input
                            className='form-check-input'
                            type='checkbox'
                            value=''
                            id='flexSwitchDefault'
                            checked={highlight}
                            onChange={handleCheckboxChange}
                          />
                          <label className='form-check-label'>
                            <h6>Destacar</h6>
                          </label>
                          <div
                            hidden={schedule.observation ? false : true}
                            style={{display: 'flex', alignItems: 'start'}}
                            data-toggle='tooltip'
                            data-placement='bottom'
                            title={schedule.observation}
                          >
                            <KTIcon iconName='information-2' className='fs-1 h-23' />
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className='col-12 mt-4'>
                    <div className='row '>
                      <div className='col-12 '>
                        <h4 style={{color: '#D31145'}}>
                          <span className='me-6'>
                            {' '}
                            {schedule.client?.physicalPerson?.preferredName
                              ? schedule.client?.physicalPerson?.preferredName
                              : schedule.client?.name}{' '}
                          </span>
                          {isAuthorizedToUpdatePerson && (
                            <span
                              role='button'
                              onClick={() => setShowEditPersonModal(true)}
                              className='ms-2 btn text-white bg-danger w-auto, p-2'
                              style={{fontSize: 12}}
                            >
                              Editar Cliente
                            </span>
                          )}
                          {isAuthorizedToViewPerson && (
                            <span
                              role='button'
                              onClick={() => {
                                window.open(
                                  toAbsoluteUrl(`/people/list/${schedule.client.id}`),
                                  '_blank'
                                )
                              }}
                              className='ms-2 btn text-white bg-danger w-auto, p-2'
                              style={{fontSize: 12}}
                            >
                              Dados do cliente
                            </span>
                          )}
                        </h4>
                      </div>
                    </div>
                  </div>
                  <div className='col-12 mb-2'>
                    <b>Aniversário:</b>{' '}
                    {moment(schedule.client.physicalPerson.birthDate).format('DD/MM/YYYY')}
                  </div>
                  <div className='col-12 mb-2'>
                    <b>Idade:</b> {schedule.client.physicalPerson.age}
                  </div>
                  <div className='col-12 mb-2'>
                    <b>Telefone:</b> {phoneMask(schedule.client.contacts[0].phone)}
                  </div>
                  {!openModal && !openModalStatus && (
                    <>
                      <div className='col-12 mt-4'>
                        <div className='row'>
                          <b className='ms-4'>Status do cliente</b>
                          <div className='col-6'>
                            <button
                              disabled
                              style={{
                                borderRadius: '30px',
                                width: '150px',
                                padding: '6px',
                                color: 'black',
                              }}
                              className={clientStatus?.colorMedical + ' fw-bold'}
                            >
                              {' '}
                              {clientStatus?.name}{' '}
                            </button>
                          </div>
                          {isAuthorizedToViewConditionMedical && (
                            <div className='col-5 text-center'>
                              <button
                                onClick={() => openContiditionMedicalModal()}
                                disabled={
                                  schedule?.client?.medicalConditions.length !== 0 ? false : true
                                }
                                style={{
                                  borderRadius: '12px',
                                  backgroundColor: authorizedMedicalCondition ? 'gray' : '#A9A9A9',
                                  width: '160px',
                                  padding: '9px',
                                  color: 'white',
                                  borderStyle: 'none',
                                }}
                              >
                                <img
                                  src={toAbsoluteUrl('/media/misc/medicalCondition.svg')}
                                  className='h-20px'
                                />{' '}
                                Condição médica{' '}
                              </button>
                            </div>
                          )}
                        </div>
                      </div>
                      {openMedicalContidition ? (
                        <MedicalConditionMedicalRecords
                          data={schedule?.client?.medicalConditions}
                        />
                      ) : (
                        <div className='col-12 mt-4'>
                          <table className='table'>
                            <thead>
                              <tr>
                                <th className='fw-bold' style={{fontSize: '16px'}}>
                                  Área
                                </th>
                                <th className='fw-bold' style={{fontSize: '16px'}}>
                                  Sessão
                                </th>
                                <th
                                  hidden={schedule.status_id === 8 ? false : true}
                                  className='fw-bold'
                                  style={{fontSize: '16px'}}
                                >
                                  Status
                                </th>
                                <th className='fw-bold' style={{fontSize: '16px'}}>
                                  Ação
                                </th>
                              </tr>
                            </thead>
                            <tbody>
                              {schedule.items.map((data, index) => (
                                <tr key={index}>
                                  <td style={{fontSize: '14px'}}>
                                    {data.saleItem?.product.name
                                      ? data.saleItem?.product.name
                                      : data.evaluationProduct.name}
                                  </td>
                                  <td style={{fontSize: '14px'}}>
                                    {data.sessionNumber} /{' '}
                                    {data.saleItem?.product.name ? data.saleItem?.sessions : '1'}
                                  </td>
                                  <td hidden={schedule.status_id === 8 ? false : true}>
                                    <span
                                      className='badge badge'
                                      style={{
                                        backgroundColor:
                                          data.medicalRecord?.status === 'Realizado'
                                            ? 'green'
                                            : 'red',
                                        color: 'white',
                                        fontSize: '12px',
                                      }}
                                    >
                                      {data.medicalRecord?.status}
                                    </span>
                                  </td>
                                  {isAuthorizedToUpdateMR && (
                                    <td>
                                      <button
                                        type='button'
                                        className={
                                          data.medicalRecord
                                            ? 'btn btn-sm btn-success'
                                            : 'btn btn-sm btn-danger'
                                        }
                                        disabled={
                                          schedule.status_id === 7 || schedule.status_id === 8
                                            ? false
                                            : true
                                        }
                                        onClick={() => openMedicalRecord(data)}
                                      >
                                        Prontuario
                                      </button>
                                    </td>
                                  )}
                                </tr>
                              ))}
                            </tbody>
                          </table>
                        </div>
                      )}
                    </>
                  )}

                  {openModal && !openModalStatus && !openModalUpdate && (
                    <CreateModalMedicalRecords data={dataMedicalRecords} formik={formik} />
                  )}

                  {openModalUpdate && !openModalStatus && !openModal && (
                    <UpdateModalMedicalRecords data={dataMedicalRecords} formik={formikUpdate} />
                  )}

                  {openModalStatus && !openModalUpdate && (
                    <StatusModalMedicalRecords data={schedule} formik={formikStatus} />
                  )}

                  {showEditPersonModal && (
                    <PersonEditModal
                      person={schedule.client}
                      provider={useScheduleCart}
                      index={0}
                      close={() => setShowEditPersonModal(false)}
                    />
                  )}

                  {showHighlightObsModal && (
                    <HighlightObsModal
                      update={refetch}
                      setHasChanges={setHasChanges}
                      data={schedule}
                      close={() => setShowHighlightObsModal(false)}
                      highlight={() => setHighlight(false)}
                    />
                  )}

                  {openModalToken && (
                    <TokenModalMedicalRecords
                      formik={formikToken}
                      Close={() => setOpenModalToken(false)}
                    />
                  )}

                  {openModal && !openModalStatus && !openModalUpdate && (
                    <>
                      <div className='col-12 mt-4'>
                        <div className='col-12 text-end'>
                          <button
                            type='button'
                            className='btn btn-secondary'
                            onClick={() => {
                              closeModalMR()
                            }}
                          >
                            Voltar
                          </button>
                          <button
                            type='submit'
                            className='btn btn-danger ms-5'
                            disabled={!formik.isValid}
                            onClick={(e) => {
                              e.preventDefault()
                              formik.handleSubmit()
                            }}
                          >
                            Salvar
                          </button>
                        </div>
                      </div>
                    </>
                  )}

                  {openModalStatus && !openModalUpdate && (
                    <>
                      <div className='col-12 mt-4'>
                        <div className='col-12 text-end'>
                          <button
                            type='button'
                            className='btn btn-secondary'
                            onClick={() => {
                              closeModalMR()
                            }}
                          >
                            Voltar
                          </button>
                          <button
                            type='submit'
                            className='btn btn-danger ms-5'
                            disabled={
                              schedule.status_id === 8 ||
                              schedule.status_id === 5 ||
                              schedule.status_id === 6
                                ? true
                                : false
                            }
                            onClick={(e) => {
                              const hasInadimplenteProducts = schedule.items.some(
                                (item) => item.saleItem?.sale?.status === 'Inadimplente'
                              )

                              if (isAuthorizedToSkipRequestToken || !hasInadimplenteProducts) {
                                formikStatus.handleSubmit()
                              } else {
                                setOpenModalTokenMedicalRecord(true)
                              }
                            }}
                          >
                            Salvar
                          </button>
                        </div>
                      </div>
                    </>
                  )}

                  {openModalUpdate && !openModalStatus && (
                    <>
                      <div className='col-12 mt-4'>
                        <div className='col-12 text-end'>
                          <button
                            type='button'
                            className='btn btn-secondary'
                            onClick={() => {
                              closeModalMR()
                            }}
                          >
                            Voltar
                          </button>
                          <button
                            type='submit'
                            className='btn btn-danger ms-5'
                            disabled={!formik.isValid || schedule.status_id === 8 ? true : false}
                            onClick={(e) => {
                              e.preventDefault()
                              formikUpdate.handleSubmit()
                            }}
                          >
                            Salvar
                          </button>
                        </div>
                      </div>
                    </>
                  )}

                  {openMedicalContidition && !openModal && !openModalStatus && !openModalUpdate && (
                    <>
                      <div className='col-12 mt-4'>
                        <div className='col-12 text-end'>
                          <button
                            type='button'
                            className='btn btn-secondary'
                            onClick={() => {
                              setOpenMedicalContidition(false)
                            }}
                          >
                            Voltar
                          </button>
                        </div>
                      </div>
                    </>
                  )}
                </div>
              </form>
            )}
            {openModalTokenMedicalRecord && (
              <ModalMedicalRecordToken
                action={() => {
                  formikStatus.handleSubmit()
                }}
                close={() => {
                  setOpenModalTokenMedicalRecord(false)
                }}
              />
            )}
          </div>
        </div>
      </div>
      <div className='modal-backdrop fade show'></div>
    </>
  )
}
